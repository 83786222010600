import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { Profile } from "../styles/Assets.styled"

export default function About({ data }) {
  const image = data.contentfulPerson.image
  const bio = data.contentfulPerson.shortBio.shortBio
  return (
    <Layout title="About">
      <div>
        <Profile alt="memoji" image={getImage(image)} width={128} />
      </div>
      <div>
        <h1>Bio</h1>
        <p>{bio}</p>
      </div>
      <div>
        Current projects:
        <ul>
          <li>
            <OutboundLink href="https://roastedby.themcclure.com">
              An amateur coffee roaster
            </OutboundLink>
          </li>
          <li>
            {/*<OutboundLink href="https://artifonica.com">*/}
            <OutboundLink href="https://musicby.themcclure.com">
              An amateur musician
            </OutboundLink>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export const memoji = graphql`
  query Author {
    contentfulPerson(name: { glob: "Mike*" }) {
      name
      id
      shortBio {
        shortBio
      }
      image {
        gatsbyImageData(
          placeholder: TRACED_SVG
          layout: CONSTRAINED
          width: 240
        )
      }
    }
  }
`
